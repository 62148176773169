import {Directive, HostListener, Input} from '@angular/core';
import {Params, Router} from '@angular/router';

@Directive({
	selector: '[routerLinkInNewTab]'
})
export class RouterLinkInNewTabDirective {
	@Input('routerLinkInNewTab') routerLink: string;
	@Input() queryParams: Params;

	constructor(private router: Router) {
	}

	@HostListener('click')
	openRouterLinkInNewTab(): void {
		const url: string = this.router.serializeUrl(
			this.router.createUrlTree([this.routerLink], {queryParams: this.queryParams})
		);
		window.open(url);
	}
}
